var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "absolute-center"
  }, [_c('div', {
    staticClass: "auth-inner"
  }, [_c('v-progress-circular', {
    attrs: {
      "width": 3,
      "color": "#1bb098",
      "indeterminate": ""
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="absolute-center">
    <div class="auth-inner">
      <v-progress-circular
        :width="3"
        color="#1bb098"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { userService } from '@/modules/admin/services'
import store from '@/store'
import { useRouter } from '@core/utils'
import { getCurrentInstance, onMounted } from '@vue/composition-api'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { router, route } = useRouter()

    onMounted(async () => {
      const data = await userService.getMe()
      const { roles, permissions } = data

      store.dispatch('currentUser/setRoles', roles)
      store.dispatch('currentUser/setAbilities', permissions)

      delete data.roles
      delete data.permissions

      store.dispatch('currentUser/setProfile', data)

      // Set user ability
      // ? https://casl.js.org/v5/en/guide/intro#update-rules
      vm.$ability.update(store.getters['currentUser/getAbilities'])

      // Redirect to target URL if redirect query is available
      if (route.value.query?.redirect) {
        return router.push({
          path: route.value.query.redirect,
        })
      }

      return router.push({
        name: 'dashboard-crm',
      })
    })

    return {}
  },
}
</script>

<style lang="scss" scoped>
.absolute-center {
  display: flex;
  width: 100%;
  height: 100vh;

  align-items: center;
  justify-content: center;
}
</style>
@/rewardPoint/admin/services